<template>
	<div id="distributionList">
		<div class="distributionList-total">
			<a-row :gutter="20">
				<a-col :span="6">
					<div class="dl-total-item">
						<div class="dlti-icon kdti-icon-bg1">
							<div class="dlti-icon-child">
								<i class="ri-user-star-fill ri-lg"></i>
							</div>
						</div>
						<div class="dlti-right">
							<div>{{total.num}}</div>
							<span>分销人数（人）</span>
						</div>
					</div> 
				</a-col>
				<a-col :span="6">
					<div class="dl-total-item">
						<div class="dlti-icon kdti-icon-bg8">
							<div class="dlti-icon-child">
								<i class="ri-team-fill ri-lg"></i>
							</div>
						</div>
						<div class="dlti-right">
							<div>{{total.userNum}}</div>
							<span>发展会员数（人）</span>
						</div>
					</div> 
				</a-col>
				<a-col :span="6">
					<div class="dl-total-item">
						<div class="dlti-icon kdti-icon-bg2">
							<div class="dlti-icon-child">
								<i class="ri-file-list-3-fill ri-lg"></i>
							</div>
						</div>
						<div class="dlti-right">
							<div>{{total.orderNum}}</div>
							<span>分销订单数（单）</span>
						</div>
					</div> 
				</a-col>
				<a-col :span="6">
					<div class="dl-total-item">
						<div class="dlti-icon kdti-icon-bg3">
							<div class="dlti-icon-child">
								<i class="ri-money-cny-circle-fill ri-lg"></i>
							</div>
						</div>
						<div class="dlti-right">
							<div>{{total.total_price}}</div>
							<span>分销订单总金额（元）</span>
						</div>
					</div> 
				</a-col>
			</a-row>
			
			<a-row :gutter="20">
				<a-col :span="6">
					<div class="dl-total-item">
						<div class="dlti-icon kdti-icon-bg4">
							<div class="dlti-icon-child">
								<i class="ri-copper-coin-fill ri-lg"></i>
							</div>
						</div>
						<div class="dlti-right">
							<div>{{ total.dis_price ? total.dis_price.price :'0.00'}}</div>
							<span>分销订单总发放分销金额（元）</span>
						</div>
					</div> 
				</a-col>
				<a-col :span="6">
					<div class="dl-total-item">
						<div class="dlti-icon kdti-icon-bg5">
							<div class="dlti-icon-child">
								<i class="ri-money-cny-circle-fill ri-lg"></i>
							</div>
						</div>
						<div class="dlti-right">
							<div>{{total.dis_price ? total.dis_price.bonus :'0.00'}}</div>
							<span>分销订单总发放奖金金额（元）</span>
						</div>
					</div> 
				</a-col>
				<a-col :span="6">
					<div class="dl-total-item">
						<div class="dlti-icon kdti-icon-bg6">
							<div class="dlti-icon-child">
								<i class="ri-currency-line ri-lg"></i>
							</div>
						</div>
						<div class="dlti-right">
							<div>{{total.withdrawals_number}}</div>
							<span>提现成功次数（次）</span>
						</div>
					</div> 
				</a-col>
				<a-col :span="6">
					<div class="dl-total-item">
						<div class="dlti-icon kdti-icon-bg7">
							<div class="dlti-icon-child ">
								<i class="ri-wallet-3-fill ri-lg"></i>
							</div>
						</div>
						<div class="dlti-right">
							<div>{{total.NoWithDrawalsPrice}}</div>
							<span>未提现总金额（元）</span>
						</div>
					</div> 
				</a-col>
			</a-row>
		 </div>
		
		<div class="el-content">
			<div class="kd-pacel">搜索参数</div>
			<a-space>
				<a-input v-model:value="searchForm.name" placeholder="昵称" />
				<a-input v-model:value="searchForm.mobile" placeholder="电话号码" />
				<a-input v-model:value="searchForm.lv_id" placeholder="等级id" />
				<a-button type="primary" @click="getDistribution(1,info.limit)">查询</a-button>
			</a-space>
			 
		</div>
		<div class="el-content">
			<a-tabs v-model:activeKey="searchForm.status" @change="getDistribution(info.page,info.limit)">
			    <a-tab-pane key="2" tab="已通过">
					<a-table :pagination='false' row-key="user_id" :data-source="info.list" :columns="[
						{title:'用户信息',dataIndex:'user',slots:{customRender:'user'}},
						{title:'一级分销商信息',dataIndex:'one',slots:{customRender:'one'}},
						{title:'二级分销商信息',dataIndex:'two',slots:{customRender:'two'}},
						{title:'消费信息',dataIndex:'consume',slots:{customRender:'consume'}},
						{title:'状态',dataIndex:'status',slots:{customRender:'status'}},
						{title:'申请时间',dataIndex:'create_time'},
						{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
					]">
						<template #user="{record}">
							<div><span class="f12">USER_ID：</span>{{record.user_id}}</div>
							<div><span class="f12">昵称：</span>{{record.name || '暂未设置'}}</div>
							<div><span class="f12">电话：</span>{{record.mobile}}</div>
							<a-tag color="#0099FF" v-if="record.lv">{{record.lv.name}}</a-tag>
						</template>
						<template #one="{record}">
							<div v-if="getUid(record.one_user)">
								<div><span class="f12">昵称：</span>{{getNickname(record.one_user)}}</div>
								<div><span class="f12">UID：</span>{{getUid(record.one_user)}}</div>
								<div><span class="f12">下线：</span>{{record.one_user_num}}人</div>
							</div>
							<div v-else>暂无</div>
							<a-space style="color: #0099FF;cursor: pointer;margin-top: 6px;">
								<a-tooltip title="修改上下级关系">
									<com-select-user :custom="true" module="distribution" @change="confirmUpdate">
										<i class="ri-edit-line ri-lg" v-has="{action:'wxapp_marketing_dis_relation'}"  @click="showRaletion(record,1)"></i>
									</com-select-user>
								</a-tooltip>
								<a-tooltip title="清除二级上线">
									<i class="ri-delete-bin-5-line ri-lg" v-has="{action:'wxapp_marketing_dis_relation'}"  @click="clearDisUpUser(1,record.user_id)"></i>
								</a-tooltip>
							</a-space>
						</template>
						<template #two="{record}">
							<div v-if="getUid(record.two_user)">
								<div><span class="f12">昵称：</span>{{getNickname(record.two_user)}}</div>
								<div><span class="f12">UID：</span>{{getUid(record.two_user)}}</div>
								<div><span class="f12">下线：</span>{{record.two_user_num}}人</div>
							</div>
							<div v-else>暂无</div>
							<a-space style="color: #0099FF;cursor: pointer;margin-top: 6px;">
								<a-tooltip title="修改上下级关系">
									<com-select-user :custom="true" module="distribution" @change="confirmUpdate">
										<i class="ri-edit-line ri-lg" v-has="{action:'wxapp_marketing_dis_relation'}"  @click="showRaletion(record,2)"></i>
									</com-select-user>
								</a-tooltip>
								<a-tooltip title="清除二级上线">
									<i class="ri-delete-bin-5-line ri-lg" v-has="{action:'wxapp_marketing_dis_relation'}"  @click="clearDisUpUser(2,record.user_id)"></i>
								</a-tooltip>
							</a-space>
						</template>
						<template #consume="{record}">
							<div><span class="f12">账户余额：</span> <span class="kdred">{{record.withdrawable_price}}</span>元</div>
							<div><span class="f12">下线消费金额：</span> <span class="kdred">{{record.xiao_price}}</span>元</div>
						</template>
						<template #status="{record}">
							<a-tag :color="record.status == 2 ?'#00CC66':'#999'" >{{record.status == 2 ?'已通过':'未审核'}}</a-tag>
						</template>
						<template #action="{record}">
							<a-space>
								<kd-button type="primary" 
									title="查看推广用户" 
									icon="ri-user-line" 
									v-has="{action:'wxapp_marketing_dis_team'}" 
									@click="showTeam(record.user_id,2)">
								</kd-button>
								<kd-button type="success" 
									title="查看推广订单" 
									icon="ri-file-list-3-line" 
									v-has="{action:'wxapp_marketing_dis_team'}" 
									@click="showTeam(record.user_id,3)">
								</kd-button>
								<kd-button type="danger" 
									title="删除" 
									icon="ri-delete-bin-5-line" 
									v-has="{action:'wxapp_marketing_dis_del'}" 
									@click="deleteDis(record.user_id)">
								</kd-button>
								<kd-button type="purple" 
									title="设置分销商等级" 
									icon="ri-medal-line" 
									v-has="{action:'wxapp_marketing_dis_set_level'}" 
									@click="showSetLevel(record.user_id)">
								</kd-button>
							</a-space>
						</template>
					</a-table>
					<div class="pager">
						<a-pagination
						    show-size-changer
						    :default-current="info.page"
						    :total="info.count"
							:default-page-size="info.limit"
						    @showSizeChange="(p,e)=>{getDistribution(info.page,e)}"
							@change="(e)=>{getDistribution(e,info.limit)}"
						/>
					</div>
				</a-tab-pane>
			    <a-tab-pane key="1" tab="待审核" force-render>
					<a-table :pagination='false' row-key="user_id" :data-source="info.list" :columns="[
						{title:'ID',dataIndex:'user_id'},
						{title:'昵称',dataIndex:'name'},
						{title:'电话',dataIndex:'mobile'},
						{title:'一级分销商信息',dataIndex:'one',slots:{customRender:'one'}},
						{title:'二级分销商信息',dataIndex:'two',slots:{customRender:'two'}},
						{title:'状态',dataIndex:'status',slots:{customRender:'status'}},
						{title:'申请时间',dataIndex:'create_time'},
						{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
					]">
						<template #one="{record}">
							<div v-if="getUid(record.one_user)">
								<div><span class="f12">昵称：</span>{{getNickname(record.one_user)}}</div>
								<div><span class="f12">UID：</span>{{getUid(record.one_user)}}</div>
								<div><span class="f12">下线：</span>{{record.one_user_num}}人</div>
							</div>
							<div v-else>暂无</div>
						</template>
						<template #two="{record}">
							<div v-if="getUid(record.two_user)">
								<div><span class="f12">昵称：</span>{{getNickname(record.two_user)}}</div>
								<div><span class="f12">UID：</span>{{getUid(record.two_user)}}</div>
								<div><span class="f12">下线：</span>{{record.two_user_num}}人</div>
							</div>
							<div v-else>暂无</div>
						</template>
						<template #status="{record}">
							<a-tag :color="record.status == 2 ?'#00CC66':'#999'" >{{record.status == 2 ?'已通过':'待审核'}}</a-tag>
						</template>
						<template #action="{record}">
							<a-space>
								<kd-button type="success" title="通过审核" icon="ri-check-double-line" v-has="{action:'wxapp_marketing_dis_check'}" @click="changeStatus(record.user_id,2)"></kd-button>
								<kd-button type="danger" title="驳回审核" icon="ri-close-line" v-has="{action:'wxapp_marketing_dis_check'}" @click="changeStatus(record.user_id,3)"></kd-button>
								<kd-button type="danger" title="删除" icon="ri-delete-bin-5-line" v-has="{action:'wxapp_marketing_dis_del'}" @click="deleteDis(record.user_id)"></kd-button>
								<kd-button type="purple" title="设置分销商等级" icon="ri-medal-line" v-has="{action:'wxapp_marketing_dis_set_level'}" @click="showSetLevel(record.user_id)"></kd-button>
							</a-space>
						</template>
					</a-table>
					<div class="pager">
						<a-pagination
						    show-size-changer
						    :default-current="info.page"
						    :total="info.count"
							:default-page-size="info.limit"
						    @showSizeChange="(p,e)=>{getDistribution(info.page,e)}"
							@change="(e)=>{getDistribution(e,info.limit)}"
						/>
					</div>
				</a-tab-pane>
			</a-tabs>
		</div>
		
		<a-modal v-model:visible="team.user" title="推广用户列表" width="1200px" :footer="null" @cancel="team.user= false">
			<div class="dl-user-search">
				<div class="mb12">
					<span class="f12">时间选择：</span>
					<a-radio-group v-model:value="searchOrder.time_type" button-style="solid" @change="changeTimeType">
						<a-radio-button :value="1">全部</a-radio-button>
						<a-radio-button :value="2">今天</a-radio-button>
						<a-radio-button :value="3">昨天</a-radio-button>
						<a-radio-button :value="4">最近七天</a-radio-button>
						<a-radio-button :value="5">最近30天</a-radio-button>
						<a-radio-button :value="6">本月</a-radio-button>
						<a-radio-button :value="7">本年</a-radio-button>
					</a-radio-group>
					<a-range-picker v-model:value="searchOrder.time" />
				</div>
				<div class="mb12">
					<span class="f12">用户类型：</span>
					<a-radio-group v-model:value="searchOrder.user_type" button-style="solid">
						<a-radio-button :value="1">全部</a-radio-button>
						<a-radio-button :value="2">一级推广人</a-radio-button>
						<a-radio-button :value="3">二级推广人</a-radio-button>
						<a-radio-button :value="4">粉丝</a-radio-button>
					</a-radio-group>
				</div>
				<div class="mb12">
					<span class="f12">姓名电话：</span>
					 <a-input-search v-model:value="searchOrder.key"  placeholder="姓名  电话  uid" enter-button style="width:400px" @search="getPromoterUser(1,team.limit)" />
				</div>
				<a-table :pagination='false' row-key="user_id" :data-source="team.list" :columns="[
					{title:'用户user_id',dataIndex:'user_id'},
					{title:'用户信息',dataIndex:'user',slots:{customRender:'user'}},
					{title:'分销用户信息',dataIndex:'disuser',slots:{customRender:'disuser'}},
					{title:'身份类型',dataIndex:'is_dis',slots:{customRender:'is_dis'}},
					{title:'支付订单数(单)',dataIndex:'pay_order_number'},
					{title:'创建时间',dataIndex:'create_time'},
				]">
					<template #user="{record}">
						<div style="display: flex;">
							<a-avatar :src="record.avatar_url"/>
							<div style="margin-left: 8px;">
								<div>{{record.nickname}}</div>
								<div>{{record.mobile}}</div>
							</div>
						</div>
					</template>
					<template #disuser="{record}">
						<template v-if="record.user_dis">
							<div><span class="f12">昵称：</span>{{record.user_dis.name}}</div>
							<div><span class="f12">电话：</span>{{record.user_dis.mobile}}</div>
							<div><span class="f12">一级下线人数：</span>{{record.user_dis.one_user_num}}</div>
							<div><span class="f12">二级下线人数：</span>{{record.user_dis.two_user_num}}</div>
						</template>
					</template>
					<template #is_dis="{record}">
						<a-tag :color="record.is_dis == 1 ?'#00CC66':'#999'">{{record.is_dis == 1 ?'分销商':'普通用户'}}</a-tag>
					</template>
				</a-table>
				<div class="pager">
					<a-pagination
					    show-size-changer
					    :default-current="team.page"
					    :total="team.count"
						:default-page-size="team.limit"
					    @showSizeChange="(p,e)=>{getPromoterUser(team.page,e)}"
						@change="(e)=>{getPromoterUser(e,team.limit)}"
					/>
				</div>
			</div>
		</a-modal>
		
		<a-modal v-model:visible="team.order" title="推广订单列表" width="1200px" :footer="null" @cancel="team.order= false">
			<div class="dl-user-search">
				<div class="mb12">
					<span class="f12">时间选择：</span>
					<a-radio-group v-model:value="searchOrder.time_type" button-style="solid" @change="changeTimeType">
						<a-radio-button :value="1">全部</a-radio-button>
						<a-radio-button :value="2">今天</a-radio-button>
						<a-radio-button :value="3">昨天</a-radio-button>
						<a-radio-button :value="4">最近七天</a-radio-button>
						<a-radio-button :value="5">最近30天</a-radio-button>
						<a-radio-button :value="6">本月</a-radio-button>
						<a-radio-button :value="7">本年</a-radio-button>
					</a-radio-group>
					<a-range-picker v-model:value="searchOrder.time" />
				</div>
				<div class="mb12">
					<span class="f12">用户类型：</span>
					<a-radio-group v-model:value="searchOrder.user_type" button-style="solid">
						<a-radio-button :value="1">全部</a-radio-button>
						<a-radio-button :value="2">一级推广人</a-radio-button>
						<a-radio-button :value="3">二级推广人</a-radio-button>
						<a-radio-button :value="4">粉丝</a-radio-button>
					</a-radio-group>
				</div>
				<div class="mb12">
					<span class="f12">姓名电话：</span>
					 <a-input-search v-model:value="searchOrder.key"  placeholder="姓名  电话  uid" enter-button style="width:400px" @search="getPromoterOrder(1,team.limit)" />
				</div>
				<a-table :pagination='false' row-key="id" :data-source="team.list" :columns="[
					{title:'ID',dataIndex:'order_id'},
					{title:'用户信息',dataIndex:'user',slots:{customRender:'user'}},
					{title:'订单编号',dataIndex:'order_num'},
					{title:'订单总金额(元)',dataIndex:'total_price'},
					{title:'分销金额(元)',dataIndex:'dis_price'},
					{title:'订单类型',dataIndex:'is_one',slots:{customRender:'is_one'}},
					{title:'创建时间',dataIndex:'created_at'},
				]">
					<template #user="{record}">
						<div>{{record.nickname}}</div>
						<div>{{record.mobile}}</div>
					</template>
					
					<template #is_one="{record}">
						<a-tag color="#0099FF" v-if="record.is_one == 1">一级订单</a-tag>
						<a-tag color="#0099FF" v-if="record.is_one == 2">二级订单</a-tag>
					</template>
				</a-table>
				<div class="pager">
					<a-pagination
					    show-size-changer
					    :default-current="team.page"
					    :total="team.count"
						:default-page-size="team.limit"
					    @showSizeChange="(p,e)=>{getPromoterOrder(team.page,e)}"
						@change="(e)=>{getPromoterOrder(e,team.limit)}"
					/>
				</div>
			</div>
		</a-modal>
		<a-modal v-model:visible="team.level" title="设置分销商等级" width="500px" @ok="setLevel"  @cancel="team.level= false">
			<a-select v-model:value="levelForm.level_id" style="width: 400px;">
				<a-select-option v-for="(item,index) in levelForm.levelList" :key="index" :value="item.id">{{item.name}}</a-select-option>
			</a-select>
		</a-modal>
	</div>
</template>

<script>
import comSelectUser from '@/components/miniapp/com-select-user.vue'
import { reactive, toRefs } from 'vue';
import distributionModel from '@/api/addons/distribution';
export default {
	components:{
		comSelectUser
	},
	computed:{
		getNickname(){
			return function(e){
				return e ? e.nickname : '';
			}
		},
		getUid(){
			return function(e){
				return e ? e.user_id : '';
			}
		}
	},
	setup(){
		const state = reactive({
			searchForm: {
				name: '',
				mobile: '',
				lv_id:'',
				status: '2'
			},
			info:{
				list:[],
				page:1,
				count:0,
				limit:10
			},
			team:{
				show:false,
				user:false,
				order:false,
				level:false,
				user_id:0,
				list:[],
				page:1,
				count:0,
				limit:10
			},
			updateReletion:{
				user_id:0,
				one_distributor:0,
				two_distributor:0,
				update_type:1,		//修改类型 1一级分销商 2二级分销商
			},
			showRaletionDialog:false,
			total:{},
			searchOrder:{
				user_type:1,	//1全部 2一级 3二级
				start_time:'',
				end_time:'',
				key:'',
				time:[],
				time_type:1,	//1全部 2今天 3昨天 4最近七天 5最近30天 6本月 7本年
			},
			levelForm:{
				user_id:0,
				level_id:"",
				levelList:[],
			}
		})
		
		
		distributionModel.disStatistics(res=>state.total = res)
		getDistribution(1,state.info.limit)
		
		function getDistribution(page,limit){
			distributionModel.getDisUser(page,limit,state.searchForm,res=>state.info = {limit,...res})
		}

		function showTeam(user_id,type){
			state.team.user_id = user_id
			state.team.list = []
			state.team.count = 0
			if( type == 2 ) getPromoterUser(1,10);
			if( type == 3 ) getPromoterOrder(1,10);
		}

		//获取推广用户
		function getPromoterUser(page,limit){
			let { user_id } = state.team
			let search = { user_id,type:1,...state.searchOrder }
			distributionModel.getDisPromoteUser(page,limit,search,res=>state.team={limit,user:true,user_id,...res})
		}

		function getPromoterOrder(page,limit){
			let { user_id } = state.team
			let search = { user_id,type:1,...state.searchOrder }
			distributionModel.getDisPromoteOrder(page,limit,search,res=>state.team={limit,order:true,user_id,...res})
		}

		//审核商户 通过2 拒绝3
		function changeStatus(user_id,value){
			distributionModel.auditDis(user_id,value,()=>getDistribution(state.info.page,state.info.limit))
		}

		//删除分销商
		function deleteDis(user_id){
			distributionModel.deleteDis(user_id,()=>getDistribution(state.info.page,state.info.limit))
		}

		function showRaletion(e,y){
			state.showRaletionDialog = true
			state.updateReletion.user_id = e.user_id
			state.updateReletion.one_distributor = e.one_user ? e.one_user.user_id : 0
			state.updateReletion.two_distributor = e.two_user ? e.two_user.user_id : 0
			state.updateReletion.update_type = y
		}

		function confirmUpdate(e){
			state.showRaletionDialog = false
			if( !e ) return
			distributionModel.updateDisUser('update',state.updateReletion,e,()=>getDistribution(state.info.page,state.info.limit))
		}

		/**
		 * 清空分销商上级关系
		 * @param {Object} update_type 1一级 2二级
		 * @param {Object} user_id
		 */
		function clearDisUpUser(update_type,user_id){
			let data = {user_id,update_type}
			distributionModel.updateDisUser('clear',data,null,()=>{
				getDistribution(state.info.page,state.info.limit)
			})
		}

		function changeTimeType(e){
			let type = e.target.value
			let currT = new Date();
			state.searchOrder.time_type = type
			//1全部 2今天 3昨天 4最近七天 5最近30天 6本月 7本年
			if( type == 2 ){
				state.searchOrder.start_time = currT.getFullYear()+'-'+(currT.getMonth()+1)+'-'+currT.getDate()+' 00:00:00'
				state.searchOrder.end_time = currT.getFullYear()+'-'+(currT.getMonth()+1)+'-'+currT.getDate()+' 23:59:59'
			}
			
			if( type == 3){
				let tempstep = currT.getTime() - 86400000
				state.searchOrder.start_time = timestampToTime(tempstep/1000)+'00:00:00'
				state.searchOrder.end_time = timestampToTime(tempstep/1000)+' 23:59:59'
			}
			
			if( type == 4 ){
				let tempstep = currT.getTime() - (86400000*7)
				state.searchOrder.start_time = timestampToTime(tempstep/1000)+'00:00:00'
				state.searchOrder.end_time = currT.getFullYear()+'-'+(currT.getMonth()+1)+'-'+currT.getDate()+' 23:59:59'
			}
			if( type == 5 ){
				let tempstep = currT.getTime() - (86400000*30)
				state.searchOrder.start_time = timestampToTime(tempstep/1000)+'00:00:00'
				state.searchOrder.end_time = currT.getFullYear()+'-'+(currT.getMonth()+1)+'-'+currT.getDate()+' 23:59:59'
			}
			if( type == 6 ){
				state.searchOrder.start_time = currT.getFullYear()+'-'+(currT.getMonth()+1)+'-01 00:00:00'
				state.searchOrder.end_time = currT.getFullYear()+'-'+(currT.getMonth()+2)+'-01 00:00:00'
			}
			if( type == 7 ){
				state.searchOrder.start_time = currT.getFullYear()+'-01-01 00:00:00'
				state.searchOrder.end_time = currT.getFullYear()+'-12-31 23:59:59'
			}
			getPromoterUser(1,state.team.limit)
		}

		function timestampToTime(timestamp) {
			let Y='',M='',D=''
			var date = new Date(timestamp * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
			Y = date.getFullYear() + '-';
			M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
			D = date.getDate() + ' ';
			return Y+M+D;
		}

		//设置分销商等级
		function showSetLevel(user_id){
			if( state.levelForm.levelList.length == 0 ){
				distributionModel.getDisLevel(res=>state.levelForm.levelList = res)
			}
			state.levelForm.user_id = user_id
			state.team.level = true
		}
		function setLevel(){
			distributionModel.setDisUserLevel(state.levelForm.user_id,state.levelForm.level_id,()=>{
				getDistribution(state.info.page,state.info.limit)
				state.team.level = false
			})
		}

		return{
			...toRefs(state),
			getDistribution,
			showTeam,
			getPromoterUser,
			getPromoterOrder,
			deleteDis,
			showRaletion,
			confirmUpdate,
			clearDisUpUser,
			changeTimeType,
			timestampToTime,
			setLevel,
			showSetLevel,
			changeStatus
		}
	}
}
</script>

<style lang="scss">
#distributionList{
	.distributionList-total{
		.dl-total-item{
			width: 100%;
			background: #FFFFFF;
			border-radius: 6px;
			height: 100px;
			display: flex;
			align-items: center;
			margin-bottom: 12px;
			
			.dlti-icon{
				margin-left: 24px;
				width: 56px;
				height: 56px;
				border-radius: 50%;
				background: rgba($color:  #4d7cfe, $alpha: .2);
				margin-right: 24px;
				&-child{
					width: 44px;
					height: 44px;
					border-radius: 50%;
					text-align: center;
					line-height: 44px;
					background: #4d7cfe;
					margin-left: 6px;
					margin-top: 6px;
					color: #FFFFFF;
				}
				i{
					position: relative;
					top: 4px;
				}
			}
			
			.kdti-icon-bg1{
				background: rgba($color:  #4d7cfe, $alpha: .2);
				>div{
					background: #4d7cfe;
				}
			}
			.kdti-icon-bg2{
				background: rgba($color:  #5afe3d, $alpha: .2);
				>div{
					background: #5afe3d;
				}
			}
			.kdti-icon-bg3{
				background: rgba($color:  #fe81fc, $alpha: .2);
				>div{
					background: #fe81fc;
				}
			}
			.kdti-icon-bg4{
				background: rgba($color:  #fe9681, $alpha: .2);
				>div{
					background: #fe9681;
				}
			}
			.kdti-icon-bg5{
				background: rgba($color:  #fe2c9c, $alpha: .2);
				>div{
					background: #fe2c9c;
				}
			}
			
			.kdti-icon-bg6{
				background: rgba($color:  #fad058, $alpha: .2);
				>div{
					background: #fad058;
				}
			}
			
			.kdti-icon-bg7{
				background: rgba($color:  #91fe02, $alpha: .2);
				>div{
					background: #91fe02;
				}
			}
			.kdti-icon-bg8{
				background: rgba($color:  #aaaaff, $alpha: .2);
				>div{
					background: #aaaaff;
				}
			}
			
			.dlti-right{
				span{
					font-size: 12px;
					color: #b4c1ce;
				}
				div{
					font-size: 20px;
					font-weight: bold;
					margin-top: 8px;
				}
			}
			
		}
		
	}
}
</style>
